// dependencies
import React, { useMemo, useCallback, useRef } from "react";
import NextLink from "next/link";
import { StyleSheet, css } from "aphrodite";

// constants
import theming from "@cloudspire/legacy-resources/src/constants/theming";

// contexts
import { useRouters } from "@cloudspire/legacy-resources/src/contexts/routers";

// libraries
import {
  lighten,
  noop,
  capitalize,
} from "@cloudspire/legacy-shared/src/libraries";
import { getRoute } from "@cloudspire/legacy-resources/src/libraries";

const { useTheme } = theming;

const styles = StyleSheet.create({
  button: {
    display: "block",
    width: "100%",
    borderRadius: "0.1875rem",
    textAlign: "center",
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    cursor: "pointer",
    fontSize: "1rem",
    transitionProperty: "background-color, color, border-color",
    transitionDuration: "160ms",
    transitionDelay: 0,
    transitionTimingFunction: "linear",
    userSelect: "none",
    borderStyle: "solid",
    borderWidth: "0.0625rem",
    textDecoration: "none",
  },
  button__disabled: {
    borderColor: "#CFDADD",
    backgroundColor: "#CFDADD",
    color: "#3A485F",
    cursor: "not-allowed",
    ":hover": {
      borderColor: "#CFDADD",
      backgroundColor: "#CFDADD",
      color: "#3A485F",
    },
    ":active": {
      borderColor: "#CFDADD",
      backgroundColor: "#CFDADD",
      color: "#3A485F",
    },
  },
});

type PropsType = {
  /**
   * Accessible srtringified version of the children.
   */
  label: string;
  style?: React.CSSProperties;
  aStyle?;
  appearance?: "primary" | "secondary" | "reset" | "classic";
  disabled?: boolean;
  external?: boolean;
  type?: "button" | "submit";
  to?: string;
  onPress?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
} & React.HTMLProps<HTMLButtonElement>;

function getProps({ props, dynamicStyles }) {
  const {
    appearance,
    label,
    disabled,
    style,
    aStyle,
    children,
    external,
    type,
    to,
    onPress,
    ...otherProps
  } = props;

  return {
    ...otherProps,
    role: "button",
    tabIndex: !disabled ? 0 : undefined,
    className: css(
      styles.button,
      dynamicStyles.button,
      dynamicStyles[`button__appearance${capitalize(appearance)}`],
      disabled && styles.button__disabled,
      aStyle
    ),
    style,
    "aria-disabled": disabled,
    children: children ? children : label,
    onClick: !disabled
      ? onPress
      : (event) => {
          event.preventDefault();
        },
  };
}

const Button: React.FunctionComponent<PropsType> = (props) => {
  const {
    to,
    type = "button",
    appearance = "classic",
    external = false,
    disabled = false,
    onPress = noop,
    ...otherProps
  } = props;

  const theme = useTheme();

  const { mainRouter } = useRouters();

  const $button = useRef<any>();

  const dynamicStyles = useMemo(() => {
    return StyleSheet.create({
      button: {
        fontFamily: theme.FONT_FAMILY,
      },
      button__appearancePrimary: {
        borderColor: theme.SECONDARY_COLOR,
        backgroundColor: theme.SECONDARY_COLOR,
        color: "#ffffff",
        ":hover": {
          borderColor: lighten(theme.SECONDARY_COLOR, 100),
          backgroundColor: lighten(theme.SECONDARY_COLOR, 100),
        },
        ":active": {
          borderColor: lighten(theme.SECONDARY_COLOR, 100),
          backgroundColor: lighten(theme.SECONDARY_COLOR, 100),
        },
      },
      button__appearanceSecondary: {
        borderColor: theme.PRIMARY_COLOR,
        backgroundColor: theme.PRIMARY_COLOR,
        color: "#ffffff",
        ":hover": {
          borderColor: theme.PRIMARY_COLOR_DARKEN,
          backgroundColor: theme.PRIMARY_COLOR_DARKEN,
        },
        ":active": {
          borderColor: theme.PRIMARY_COLOR_DARKEN,
          backgroundColor: theme.PRIMARY_COLOR_DARKEN,
        },
      },
      button__appearanceReset: {
        borderColor: "#ff0000",
        backgroundColor: "#ffffff",
        color: "#ff0000",
        ":hover": {
          borderColor: "#ff0000",
          backgroundColor: "#ffffff",
        },
        ":active": {
          borderColor: "#ff0000",
          backgroundColor: "#ffffff",
        },
      },
      button__appearanceClassic: {
        borderColor: "#cfdadd",
        backgroundColor: "#ffffff",
        color: "#000000",
        ":hover": {
          borderColor: "#cfdadd",
          backgroundColor: "#ffffff",
        },
        ":active": {
          borderColor: "#cfdadd",
          backgroundColor: "#ffffff",
        },
      },
    });
  }, [theme]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement | HTMLButtonElement>) => {
      if (!disabled) {
        switch (event.key) {
          case "Enter":
          case " ": {
            event.preventDefault();

            $button.current.click();

            break;
          }
        }
      }
    },
    [disabled]
  );

  if (to) {
    const { nextLink, href } = getRoute({
      to,
      external,
      router: mainRouter,
    });

    if (nextLink) {
      return (
        <NextLink href={href} passHref={true} legacyBehavior>
          <a
            ref={$button}
            {...getProps({
              props: {
                type,
                appearance,
                disabled,
                external,
                onPress,
                ...otherProps,
              },
              dynamicStyles,
            })}
          />
        </NextLink>
      );
    } else {
      return (
        <a
          ref={$button}
          href={to}
          {...getProps({
            props: {
              type,
              appearance,
              disabled,
              external,
              onPress,
              ...otherProps,
            },
            dynamicStyles,
          })}
        />
      );
    }
  } else {
    return (
      <button
        ref={$button}
        disabled={disabled}
        type={type}
        {...getProps({
          props: {
            type,
            appearance,
            disabled,
            external,
            onPress,
            ...otherProps,
          },
          dynamicStyles,
        })}
        onKeyDown={handleKeyDown}
      />
    );
  }
};

export default Button;
